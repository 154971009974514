import "./lassoToolInstructionPanel.less";

export const LassoToolInstructionPanel = () => {
    return (
        <div className="lasso-tool-instruction-panel">
            <div className="lasso-tool-instruction-panel-title">Lasso Tool</div>
            <div className="lasso-tool-instruction-panel-description">
                Draw a shape to select or deselect zones. Click to set vertices and double-click to
                finish, or drag to draw a boundary.
            </div>
        </div>
    );
};
