import { createSelector } from "reselect";
import * as analysisConfigurationSelectors from "@app/analysis/state/analysisConfiguration.selectors";
import { ZOOM } from "@app/analysisLightning/base/map/lightningMap.constants";
import { LIGHTNING_ANALYSIS_TYPES } from "@app/analysisLightning/base/state/analysisLightning.constants";
import {
    convertTimePeriodsToApi,
    getSelectedRoadIds,
} from "@app/analysisLightning/base/state/analysisLightning.helpers";
import type { ITimePeriod } from "@app/analysisLightning/base/state/analysisLightning.types";
import { IMonths } from "@app/analysisLightning/base/state/analysisLightning.types";
import type { TRootState } from "@app/store";
import * as staticDataSelectors from "@app/store/staticData/state/staticData.selectors";
import { MODES_OF_TRAVEL, ORG_COUNTRIES } from "@common/constants/analysis.constants";
import {
    ROAD_TYPES,
    TRoadId,
    TRoadTypeKey,
} from "@common/features/zonesManager/state/zonesManager.constants";
import type {
    IAvailableDataPeriodAPI,
    ISATCAvailableDataPeriodAPI,
} from "@common/services/server/analysesApi.types";
import { arrayIncludes } from "@common/utils/arrayIncludes";

const DEFAULT_MONTHS = {
    startMonth: 1,
    endMonth: 12,
};

export const getLightningState = (state: TRootState) => state.analysisLightning.base;

export const getConfigurations = (state: TRootState) => getLightningState(state).configurations;

export const getSelectedGeographies = (state: TRootState) => getConfigurations(state).geographies;

export const getSelectedTimePeriods = (state: TRootState) => getConfigurations(state).timePeriods;

export const getUiStates = (state: TRootState) => getLightningState(state).uiStates;

export const getLocation = (state: TRootState) => getUiStates(state).location;

export const getActiveStep = (state: TRootState) => getUiStates(state).activeStep;

export const getMapError = (state: TRootState) => getUiStates(state).mapError;

export const getHoveredGeography = (state: TRootState) => getUiStates(state).hoveredGeography;

export const getRoadClassifications = (state: TRootState) =>
    getConfigurations(state).roadClassifications;

export const getZoneSelectionConfig = (state: TRootState) =>
    getConfigurations(state).zoneSelectionConfig;

export const getAnalysisType = (state: TRootState) => getConfigurations(state).analysisType;

export const getHoveredZone = (state: TRootState) => getUiStates(state).hoveredZone;

export const getAnalysisTypeName = createSelector(getConfigurations, ({ analysisType }) => {
    if (!analysisType) return "";

    return LIGHTNING_ANALYSIS_TYPES[analysisType]?.name || "";
});

export const getFilteredRoads = createSelector(
    getUiStates,
    getRoadClassifications,
    getAnalysisType,
    ({ roads }, roadClassifications, analysisType) => {
        const roadIds = getSelectedRoadIds(roadClassifications, analysisType);

        return roads.filter(_road => arrayIncludes(roadIds, _road.highway));
    },
);

export const getIsLocateRegionValid = createSelector(
    getLocation,
    getConfigurations,
    (location, { geographies }) => !!location?.id || !!geographies.length,
);

export const getIsSelectRegionValid = createSelector(
    getConfigurations,
    ({ geographies }) => !!geographies.length,
);

export const getIsSelectTimePeriodValid = createSelector(
    getConfigurations,
    ({ timePeriods }) => !!timePeriods.length,
);

export const getIsTtSelectTimePeriodValid = createSelector(
    getConfigurations,
    ({ timePeriods }) => {
        if (!timePeriods.length) return false;

        const monthsCount = timePeriods.reduce(
            (result, period) => result + (period.months as number[]).length,
            0,
        );

        return monthsCount <= 24;
    },
);

export const getSegmentsCount = createSelector(getFilteredRoads, filteredRoads => {
    return filteredRoads.reduce((result, road) => {
        return result + road.segment_count;
    }, 0);
});

export const getMinInteractiveZoomForGeographyType = createSelector(
    getConfigurations,
    ({ geographyType }) => {
        switch (geographyType) {
            case "STATE":
                return ZOOM.MIN_INTERACTIVE_STATE;
            case "COUNTY":
                return ZOOM.MIN_INTERACTIVE_COUNTY;
            default:
                return ZOOM.MIN_INTERACTIVE;
        }
    },
);

export const getShouldPreserveHoveredGeography = createSelector(
    getHoveredGeography,
    hoveredGeography => hoveredGeography?.shouldPreserve,
);

export const getSelectedGeographyIds = createSelector(getConfigurations, ({ geographies }) =>
    geographies.map(geography => String(geography.zone_id)),
);

export const getSelectedGeographyForLayerGroupRequest = createSelector(
    getSelectedGeographies,
    geographies => {
        const zonesGroupedByZoneKinds = geographies.reduce((map, geography) => {
            if (!map[geography.zone_kind_id]) map[geography.zone_kind_id] = [];

            map[geography.zone_kind_id].push(String(geography.zone_id));

            return map;
        }, {} as { [key: string]: Array<string> });

        return Object.entries(zonesGroupedByZoneKinds).map(([zoneKindId, zoneIds]) => ({
            zone_kind_id: zoneKindId,
            zone_ids: zoneIds,
        }));
    },
);

export const getSelectedGeographyForZoneKindLayerRequest = createSelector(
    getSelectedGeographies,
    geographies => {
        return geographies.map(({ zone_id, zone_kind_id }) => ({
            zone_id: +zone_id,
            zone_kind_id,
        }));
    },
);

export const getSelectedGeographyForBoundingBoxRequest = createSelector(
    getSelectedGeographies,
    geographies => {
        if (!geographies?.length) return { zones: [] };

        const zonesData = geographies.reduce((result, zone) => {
            const zoneIds = result[zone.zone_kind_id]?.zone_ids || [];

            zoneIds.push(String(zone.zone_id));

            result[zone.zone_kind_id] = {
                zone_kind_id: zone.zone_kind_id,
                zone_ids: zoneIds,
            };

            return result;
        }, {} as { [key: number]: { zone_kind_id: number; zone_ids: Array<string> } });

        return { zones: Object.values(zonesData) };
    },
);

export const getRoadIds = createSelector(getRoadClassifications, roadClassifications =>
    roadClassifications.reduce(
        (result, roadTypeId) =>
            result.concat(ROAD_TYPES[roadTypeId.toUpperCase() as TRoadTypeKey].roadIds),
        [] as TRoadId[],
    ),
);

export const getApiData = createSelector(
    getConfigurations,
    getLocation,
    (
        {
            analysisName,
            analysisType,
            projectFolder,
            roadClassifications,
            timePeriods,
            draftId,
            geographies,
            isCopy,
        },
        location,
    ) => {
        const projectFolderId = projectFolder
            ? { project_folder_id: Number(projectFolder.project_folder_id) }
            : {};

        const dateRanges = convertTimePeriodsToApi(timePeriods);
        const draftData = draftId ? { is_draft: true, draft_id: draftId } : {};

        return {
            is_copy_project: isCopy,
            project_name: analysisName,
            project_type: analysisType!,
            date_ranges: dateRanges,
            ...draftData,
            ...projectFolderId,
            location,
        };
    },
);

export const getTtAvailableDataPeriods = createSelector(
    analysisConfigurationSelectors.getAvailableDataMonthsByMode,
    (availableDataMonths: any) => {
        const periodsByYears = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.id].reduce(
            (result: { [key: number]: ITimePeriod }, period: IAvailableDataPeriodAPI) => {
                if (period.country === ORG_COUNTRIES.CA.code) return result;

                if (!result[period.year]) {
                    result[period.year] = {
                        year: period.year,
                        months: { startMonth: period.month },
                    };
                } else {
                    (result[period.year].months as IMonths).endMonth = period.month;
                }
                return result;
            },
            {},
        ) as { [key: number]: Required<ITimePeriod> };

        return Object.values(periodsByYears).sort((a, b) => b.year - a.year) as Array<ITimePeriod>;
    },
);

export const getSATCAvailableDataPeriods = createSelector(
    staticDataSelectors.getSATCAvailableDataPeriods,
    (satcAvailableDataPeriods: Array<ISATCAvailableDataPeriodAPI>) => {
        return satcAvailableDataPeriods
            .reduce((result: Array<ITimePeriod>, period: ISATCAvailableDataPeriodAPI) => {
                if (period.has_full_year && period.country === ORG_COUNTRIES.US.code) {
                    result.push({
                        year: period.year,
                        months: DEFAULT_MONTHS,
                    });
                }

                return result;
            }, [] as Array<ITimePeriod>)
            .sort((a, b) => b.year - a.year);
    },
);
