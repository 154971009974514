import { useEffect, useMemo } from "react";
import { merge } from "lodash-es";
import mapboxGL, { Map } from "mapbox-gl";
import {
    EControlPositions,
    TControlConfig,
    TSelectionToolsConfig,
} from "@common/components/baseMap/baseMap.types";
import { NorthArrowControl } from "@common/components/baseMap/customControls";
import { addContentToButton } from "@common/components/baseMap/customControls/customControls.helpers";
import { DEFAULT_DISPLAY_CONFIG } from "@common/components/baseMap/customControls/mapLayers";
import { useZoneSelectionControl } from "@common/components/baseMap/customControls/zoneSelectionTools/baseMapControl";
import { TProps as TLocationControlProps, useLocationControl } from "./useLocationControl";
import { useMapLayersControl } from "./useMapLayersControl";
import { TProps as TMapLoaderControlProps, useMapLoaderControl } from "./useMapLoaderControl";
import { TProps as TMapStyleControlProps, useMapStyleControl } from "./useMapStyleControl";
import { useMeasurementToolControl } from "./useMeasurementToolControl";

type TProps = {
    map: Map | null;
    mapId?: string;
    configuration?: Partial<TControlConfig>;
    mapContainerRef: TMapStyleControlProps["mapContainerRef"];
    style: TMapStyleControlProps["style"];
    onStyleChange: TMapStyleControlProps["onStyleChange"];
    isLoading: TMapLoaderControlProps["isLoading"];
    location: TLocationControlProps["location"];
    onLocationChange: TLocationControlProps["onLocationChange"];
};

export const useMapControls = ({
    map,
    mapId,
    configuration,
    mapContainerRef,
    style,
    onStyleChange,
    isLoading,
    location,
    onLocationChange,
}: TProps) => {
    const {
        position,
        controls,
        imgs,
        selectionToolsConfig = {} as TSelectionToolsConfig<unknown>,
    } = useMemo(
        () =>
            merge(
                {
                    position: EControlPositions.TOP_LEFT,
                    controls: {
                        mapStyle: true,
                        location: true,
                        northArrow: true,
                        compact: true,
                        fullScreen: true,
                        navigation: true,
                        mapLoader: true,
                        mapLayers: false,
                        measurementTool: true,
                    },
                    imgs: {
                        northArrow: "/img/arrow-direction.svg",
                    },
                },
                configuration,
            ) as Omit<TControlConfig, "controls"> & {
                controls: Required<TControlConfig["controls"]>;
            },
        [configuration],
    );

    // Order of useEffect/useControl hooks is important as it defines in which order controls are shown
    useEffect(() => {
        if (!map || !controls.northArrow || !imgs?.northArrow) return;

        map.addControl(new NorthArrowControl(imgs.northArrow), position);
    }, [map, controls.northArrow, imgs?.northArrow, position]);

    const mountZoomToLocationInput = useLocationControl({
        map,
        config: controls.location,
        position,
        mapId,
        location,
        onLocationChange,
    });

    useEffect(() => {
        if (!map) return;

        if (controls.navigation) {
            map.addControl(new mapboxGL.NavigationControl({ showCompass: false }), position);
        }

        if (controls.fullScreen) {
            map.addControl(new mapboxGL.FullscreenControl(), position);
            const fullscreenButton = mapContainerRef.current?.querySelector(
                ".mapboxgl-ctrl-fullscreen",
            ) as HTMLButtonElement;
            addContentToButton({
                button: fullscreenButton,
                tooltip: "Enter fullscreen",
            });
        }
        if (controls.compact) {
            map.addControl(
                new mapboxGL.AttributionControl({
                    compact: true,
                }),
            );
        }

        const zoomInButton = mapContainerRef.current?.querySelector(
            ".mapboxgl-ctrl-zoom-in",
        ) as HTMLButtonElement;
        addContentToButton({
            button: zoomInButton,
            tooltip: "Zoom In",
        });
        const zoomOutButton = mapContainerRef.current?.querySelector(
            ".mapboxgl-ctrl-zoom-out",
        ) as HTMLButtonElement;
        addContentToButton({
            button: zoomOutButton,
            tooltip: "Zoom Out",
        });
    }, [
        map,
        controls.navigation,
        controls.fullScreen,
        position,
        controls.compact,
        mapContainerRef,
    ]);

    const mountMeasurementTool = useMeasurementToolControl({
        map,
        enabled: controls.measurementTool,
        position,
    });

    const mapLayersConfig = configuration?.mapLayersConfig || DEFAULT_DISPLAY_CONFIG;
    const mountMapLayersControl = useMapLayersControl({
        map,
        enabled:
            controls.mapLayers &&
            !!(mapLayersConfig.showOsmLayers || mapLayersConfig.showEnhancedLayers),
        position,
        mapLayersConfig: configuration?.mapLayersConfig,
    });

    const mountMapLoaderControl = useMapLoaderControl({
        map,
        enabled: controls.mapLoader,
        isLoading,
        position,
    });

    const mountBaseMapConfigurationModal = useMapStyleControl({
        map,
        enabled: controls.mapStyle,
        position,
        style,
        onStyleChange,
        mapContainerRef,
    });

    const { mountSelectionTools, zoneSelectionTools, mountLassoToolInstructionPanel } =
        useZoneSelectionControl<unknown>(map, selectionToolsConfig);

    return {
        controls,
        zoneSelectionTools,
        mountBaseMapConfigurationModal,
        mountZoomToLocationInput,
        mountMapLayersControl,
        mountMeasurementTool,
        mountMapLoaderControl,
        mountSelectionTools,
        mountLassoToolInstructionPanel,
    };
};
