import moment from "moment";
import {
    calculateBinColors,
    calculateDataDependentBinColors,
} from "@app/viz3/base/state/baseViz.helpers";
import type { ISegment } from "@app/viz3/base/state/baseViz.types";
import { CREATE_SEGMENTS_GROUP_TYPES } from "@app/viz3/ctVisualization/components/createSegmentsGroup/segmentsGroup.constants";
import type { IAnalysisDatePeriod, IColors, IVizAnalysis } from "@app/viz3/viz3.types";
import { OSM_ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import {
    ROAD_TYPES,
    TRoadTypeKey,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { capitalizeTheFirstLetter } from "@common/formatters/formatString";
import { formatUsingLocale, formatValue } from "@common/formatters/formatValue";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { SEGMENT_FILTERS } from "./baseLightningViz.constants";
import type {
    IFullProjectZoneGroup,
    IProjectZoneGroup,
    ISummaryStatistic,
    IZoneScores,
    TRoadType,
    TSelectedSegmentFilters,
} from "./baseLightningViz.types";

const getTTMonthsDisplay = (months: Array<number>) => {
    if (!months.length) return "";

    const formatDataMoment = (month: number) => moment(month, "M").format("MMMM");

    if (months.length === 12) {
        return `${formatDataMoment(months[0])} - ${formatDataMoment(months[months.length - 1])}`;
    }

    const monthGroups = months.reduce((currGroup: number[][], month, index) => {
        if (index === 0) {
            return [[month]];
        }

        const lastGroup = currGroup[currGroup.length - 1];

        if (month - 1 === lastGroup[lastGroup.length - 1]) {
            lastGroup.push(month);
        } else {
            currGroup.push([month]);
        }

        return currGroup;
    }, []);

    return monthGroups
        .map(group => {
            if (group.length === 1) {
                return formatDataMoment(group[0]);
            }

            return `${formatDataMoment(group[0])} - ${formatDataMoment(group[group.length - 1])}`;
        })
        .join(", ");
};

export const convertTTDataPeriodsToString = (dateRanges: Array<IAnalysisDatePeriod>) => {
    const groups: Record<string, Set<number>> = {};

    dateRanges.forEach(range => {
        const { month_num, year_num } = range;

        if (!groups[year_num]) {
            groups[year_num] = new Set();
        }

        groups[year_num].add(month_num);
    });

    const datesArr = Object.entries(groups).map(([year, months]) => {
        return `${year} (${getTTMonthsDisplay(Array.from(months))})`;
    });

    return datesArr.reverse().join(", ");
};

export const convertDatePeriodsToYearsArray = (datePeriods: IVizAnalysis["data_periods"]) => {
    const years = new Set();

    datePeriods.forEach(period => years.add(period.year_num));

    return Array.from(years) as Array<number>;
};

export const convertSelectedSegmentFiltersToLabels = ({
    selectedSegmentFilters,
    segmentGroups,
    selectedRoads,
    selectedRoad,
}: {
    selectedSegmentFilters: TSelectedSegmentFilters;
    segmentGroups: Array<IProjectZoneGroup>;
    selectedRoads: Array<TRoadType["id"]>;
    selectedRoad?: {
        county_name: string;
        city_name: string;
        highway: string;
    };
}) => {
    if (!selectedSegmentFilters) {
        return { primaryLabel: "" };
    }

    if (selectedSegmentFilters.code === SEGMENT_FILTERS.ALL.code) {
        return { primaryLabel: SEGMENT_FILTERS.ALL.display };
    }

    if (selectedSegmentFilters.code === SEGMENT_FILTERS.ROAD_CLASSIFICATIONS.code) {
        return {
            primaryLabel: selectedRoads.reduce((result, value, index) => {
                const filterName = ROAD_TYPES[value.toUpperCase() as TRoadTypeKey].name;

                if (!filterName) return result;

                if (!index) {
                    return `${filterName}`;
                }

                return `${result}, ${filterName}`;
            }, ""),
        };
    }

    if (selectedSegmentFilters.code === SEGMENT_FILTERS.COUNTY.code) {
        if (!selectedRoad) return { primaryLabel: "" };
        return {
            primaryLabel: selectedRoad.county_name,
            secondaryLabel: `, ${selectedRoad.city_name}, ${capitalizeTheFirstLetter(
                selectedRoad.highway,
            )}`,
        };
    }

    if (selectedSegmentFilters.code === SEGMENT_FILTERS.STATE.code) {
        return { primaryLabel: "" };
    }

    return {
        // @ts-ignore
        primaryLabel: selectedSegmentFilters.values.reduce((result, value, index) => {
            const groupName = segmentGroups.find(
                group => group.project_zone_group_id === value,
            )?.project_zone_group_name;

            if (!groupName) return result;

            if (!index) {
                return `${groupName}`;
            }

            return `${result}, ${groupName}`;
        }, ""),
    };
};

export const getLightningVizZonesByZoneKind = (
    zones: {
        zone_id: number;
        zone_name: string;
        zone_kind_id: typeof OSM_ZONE_KINDS.OSM_SATC.id | typeof OSM_ZONE_KINDS.OSM_TERTIARY.id;
    }[],
) => {
    if (!zones?.length) return { zones: [] };

    const zonesData = {} as Record<
        number,
        {
            zone_ids: Array<number>;
            zone_kind_id:
                | typeof OSM_ZONE_KINDS.OSM_SATC.id
                | typeof OSM_ZONE_KINDS.OSM_TERTIARY.id;
        }
    >;

    for (const zone of zones) {
        const zoneIds = zonesData[zone.zone_kind_id]?.zone_ids || [];

        zonesData[zone.zone_kind_id] = {
            zone_kind_id: zone.zone_kind_id,
            zone_ids: zoneIds.concat(zone.zone_id),
        };
    }

    return { zones: Object.values(zonesData) };
};

export const getNumber = (value: string) => parseFloat(value.replace(/,|%/g, ""));

export const formatNumberValue = (
    value?: number | string | null,
    config: {
        markThousands?: boolean;
        dropTrailingZeroes?: boolean;
        shouldShowAsPercentage?: boolean;
        shouldShowAsFractional?: boolean;
    } = {},
) => {
    if (!value && value !== 0) return "";

    let formattedValue = formatUsingLocale(Number(value), {
        shouldShowAsFractional: config.shouldShowAsFractional,
        shouldShowAsPercentage: config.shouldShowAsPercentage,
    });

    if (config.dropTrailingZeroes) {
        formattedValue = formattedValue.replace(/0+$/, "");
        // It's possible that after zeroes truncation value will be as '18.' -> remove trailing '.'
        formattedValue =
            formattedValue.indexOf(".") === formattedValue.length - 1
                ? formattedValue.replace(/.$/, "")
                : formattedValue;
    }

    if (config.shouldShowAsPercentage) return `${formattedValue}%`;
    return config.markThousands ? formattedValue : Number(value).toFixed(0);
};

export const getUpdatedSelectedSegments = (
    currentSelectedSegments: Array<ISegment>,
    newSelectedSegments: Array<ISegment>,
    isAddMode: boolean,
) => {
    const mapOfCurrentSelectedSegments: Map<ISegment["zone_id"], ISegment> = new Map(
        currentSelectedSegments.map(segment => [segment.zone_id, segment]),
    );
    const mapOfNewSegments: Map<ISegment["zone_id"], ISegment> = new Map(
        newSelectedSegments.map(segment => [segment.zone_id, segment]),
    );

    if (!isAddMode) {
        return currentSelectedSegments.filter(segment => !mapOfNewSegments.has(segment.zone_id));
    }

    mapOfNewSegments.forEach((value, key) => mapOfCurrentSelectedSegments.set(key, value));

    return Array.from(mapOfCurrentSelectedSegments.values());
};

export const getRoadsFromSegments = (segments: Array<ISegment>) => {
    const groupedSegments = {} as Record<string, Array<ISegment>>;

    for (const segment of segments) {
        if (!groupedSegments[segment.road_name]) {
            groupedSegments[segment.road_name] = [segment];
            continue;
        }

        groupedSegments[segment.road_name].push(segment);
    }

    return Object.entries(groupedSegments).map(([roadName, _segments]) => ({
        id: roadName,
        segments: _segments,
    }));
};

export const getSelectedZoneGroupDataToState = (
    zoneGroup: IFullProjectZoneGroup,
    zones: Array<ISegment>,
) => {
    const { zones: zonesData } = zoneGroup;

    const groupType =
        zoneGroup.project_zone_group_type === CREATE_SEGMENTS_GROUP_TYPES.corridors.type
            ? CREATE_SEGMENTS_GROUP_TYPES.corridors
            : CREATE_SEGMENTS_GROUP_TYPES.areas;

    const selectedSegments = zones.filter(zone =>
        arrayIncludes(zonesData[0].zone_ids, Number(zone.zone_id)),
    );

    return {
        selectedZoneGroup: zoneGroup,
        groupType,
        groupName: zoneGroup.project_zone_group_name,
        selectedSegments,
    };
};

export const createBinItems = ({
    zoneScores,
    statistics,
    isPercentage = false,
    fractionDigitsCount = 2,
    colors,
    isDataDependent = false,
}: {
    zoneScores?: IZoneScores;
    statistics?: ISummaryStatistic;
    isPercentage?: boolean;
    fractionDigitsCount?: number;
    colors: IColors;
    isDataDependent?: boolean;
}) => {
    if (!zoneScores || !statistics) return [];

    const binStarts = zoneScores.bins.kmeans;
    const binColors = isDataDependent
        ? calculateDataDependentBinColors(colors, binStarts)
        : calculateBinColors(colors, binStarts.length);

    const shouldShowAsFractional = binStarts.some(bin => bin % 1 !== 0);

    const getBinLabel = (binStart: number, binEnd: number) => {
        let _binStart;
        let _binEnd;

        if (isPercentage) {
            _binStart = formatValue(binStart, {
                shouldShowAsPercentage: true,
                fractionDigitsCount,
            });
            _binEnd = formatValue(binEnd, {
                shouldShowAsPercentage: true,
                fractionDigitsCount,
            });

            if (binEnd > binStart) return `${_binStart}-${_binEnd}`;
            return `${_binStart}`;
        }

        _binStart = formatValue(binStart, { shouldShowAsFractional, fractionDigitsCount });
        _binEnd = formatValue(binEnd, { shouldShowAsFractional, fractionDigitsCount });

        if (binEnd > binStart) return `${_binStart}-${_binEnd}`;

        return `${_binStart}`;
    };

    const getBinEnd = (binStart: number, index: number) => {
        if (index < binStarts.length - 1) {
            const increment = isPercentage || shouldShowAsFractional ? 0.0001 : 1;

            const tryBinEnd = binStarts[index + 1] - increment;
            // If single-valued bin, set binEnd to binStart
            return tryBinEnd >= binStarts[index] ? tryBinEnd : binStarts[index];
        } else {
            return statistics.max;
        }
    };

    return binStarts
        .map((binStart, index) => {
            const binEnd = getBinEnd(binStart, index);

            return {
                color: binColors[index],
                label: getBinLabel(binStart, binEnd),
            };
        })
        .reverse();
};

export const createStaticPercentageBinItems = ({
    binStarts,
    statistics,
    colors,
}: {
    binStarts?: Array<number>;
    statistics: ISummaryStatistic;
    colors: IColors;
}) => {
    if (!binStarts || !statistics) return [];

    const binColors = calculateBinColors(
        colors,
        binStarts[binStarts.length - 1] === statistics.max
            ? binStarts.length - 1
            : binStarts.length,
    );

    const getBinLabel = (binStart: number, binEnd: number) => {
        const _binStart = formatValue(binStart, {
            shouldShowAsPercentage: true,
            fractionDigitsCount: 0,
        });
        const _binEnd = formatValue(binEnd, {
            shouldShowAsPercentage: true,
            fractionDigitsCount: 0,
        });

        if (binEnd > binStart) return `${_binStart}-${_binEnd}`;
        return `${_binStart}`;
    };

    const getBinEnd = (binStart: number, index: number) => {
        if (index < binStarts.length - 1) {
            const increment = 0.0001;

            const tryBinEnd = binStarts[index + 1] - increment;
            // If single-valued bin, set binEnd to binStart
            return tryBinEnd >= binStarts[index] ? tryBinEnd : binStarts[index];
        } else {
            return statistics.max;
        }
    };

    return binStarts
        .reduce((res, binStart, index) => {
            if (binStart === statistics.max && index === binStarts.length - 1) {
                return res;
            }

            const binEnd = getBinEnd(binStart, index);

            res.push({
                color: binColors[index],
                label: getBinLabel(binStart, binEnd),
            });
            return res;
        }, [] as Array<{ color: string; label: string }>)
        .reverse();
};
